import PageLayout from '@global/page-layout';
import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { ImageBadge } from '@components/imageBadge/imageBadge';
import CTA2 from '@containers/CTA2';
import { popup } from '@src/global/utils/typeformPopup';

const IndustriesPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query allFullIndustriesSectionJson {
      fullIndustriesSectionJson {
        title
        subTitle
        listIndustries
        highlightedIndustries {
          slug
          title
          image
          description
          tag
        }
      }
    }
  `);

  const {
    title,
    subTitle,
    listIndustries,
    highlightedIndustries,
  } = data.fullIndustriesSectionJson;
  return (
    <PageLayout title="Industries" location={location}>
      <div className="bg-gray-200 pt-6">
        <section className="text-gray-700 body-font">
          <div className="container px-5 py-24 mx-auto">
            <div className="text-center mb-20">
              <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
                {title}
              </h1>
              <p className="leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                {subTitle}
              </p>
            </div>
          </div>
        </section>
        <section className="text-gray-700 body-font bg-white">
          <div className="container px-5 pb-24 mx-auto">
            <div className="flex flex-wrap -m-4">
              {highlightedIndustries.map((highlightedIndustry, index) => (
                <div className="lg:w-1/3 md:w-1/2 p-4 w-full" key={index}>
                  <div className="block relative h-48 rounded overflow-hidden">
                    <ImageBadge
                      imageURL={highlightedIndustry.image}
                      tag={highlightedIndustry.tag}
                    />
                  </div>

                  <div className="mt-4">
                    <h1 className="text-xl title-font text-lg font-medium text-gray-900 mb-3">
                      {highlightedIndustry.title}
                    </h1>
                    <p className="leading-relaxed text-base">
                      {highlightedIndustry.description}
                    </p>
                    <div className="text-gray-600 hover:text-gray-900">
                      <Link
                        to={`${highlightedIndustry.slug}/`}
                        className="inline-flex items-center justify-center py-3 border border-transparent text-base font-medium rounded-md text-gray-600 bg-white hover:text-gray-900"
                      >
                        Learn more
                        <svg
                          className="ml-1 h-5 w-5 text-gray-600 hover:text-gray-900"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <CTA2
          onPress={() => popup()}
          title="Join the next generation of businesses using PiCortex to transform their business today!"
        />
      </div>
    </PageLayout>
  );
};

IndustriesPage.propTypes = {
  location: PropTypes.object,
};
export default IndustriesPage;
