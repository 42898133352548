import React from 'react';
import PropTypes from 'prop-types';
import { TWButton } from '@styled/components';

const CTA2 = ({ title, onPress }) => (
  <div className="text-gray-700 body-font">
    <div className="container px-5 py-24 mx-auto">
      <div className="lg:w-2/3 flex flex-col sm:flex-row sm:items-center items-start mx-auto">
        <h1 className="flex-grow sm:pr-16 text-2xl font-medium leading-normal title-font text-gray-900">
          {title}
        </h1>
        <TWButton
          // className="w-3/12 flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-green-600 bg-white hover:text-green-500 focus:outline-none focus:border-green-300 focus:shadow-outline-green transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
          className="w-full mt-4 lg:mt-0 lg:w-3/12 inline-flex items-center justify-center px-0 lg:px-8 py-3 border border-transparent border-solid border-2 border-primaryColor hover:border-primaryColor text-base leading-6 font-medium rounded-md text-white bg-primaryColor hover:text-primaryColor hover:bg-white transition duration-150 ease-in-out"
          onClick={() => onPress()}
          title="Get started"
        />
      </div>
    </div>
  </div>
);

CTA2.propTypes = {
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default CTA2;
