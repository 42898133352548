import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ImageContainer = styled.div.attrs({
  className: 'rounded-lg h-64 overflow-hidden',
})`
  position: relative;
`;

const BadgeContainer = styled.div.attrs({
  className:
    'inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-green-100 text-green-800',
})`
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  padding: 5px 10px;
  margin: 5px;
`;

export const ImageBadge = ({ imageURL, tag, alt }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <ImageContainer>
      {tag ? <BadgeContainer>{tag}</BadgeContainer> : null}

      <img
        alt={alt}
        className="object-cover object-center h-full w-full"
        src={imageURL}
        onLoad={() => {
          setIsLoaded(true);
        }}
        style={{ opacity: isLoaded ? 1 : 0 }}
      />
    </ImageContainer>
  );
};

ImageBadge.propTypes = {
  imageURL: PropTypes.string.isRequired,
  tag: PropTypes.string,
  alt: PropTypes.string,
};
